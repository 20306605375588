<kng-navbar style="height:100%">

  <div class="root-container">
    <div class="basic-setting">
    <!-- USER i18n -->
    <div class="kng-boxed theme i18n">
      <kng-slider class="label" [small]="true" (change)="doLocaleSwitch()">
        {{label.nav_i18n}}&nbsp;({{locale}})
      </kng-slider>            
    </div>

    <!-- USER login -->
    <div class="kng-boxed theme" [hidden]="user.isAuthenticated()">
      <div class="label" [innerHtml]="tagline.t[locale]"></div>
      <div class="content">
        <button class="" [routerLink]="'/store/'+store+'/home/me/login'" [relativeTo]="'/store/'+store+'/home'">{{label.nav_login}}</button> 
      </div>
    </div>

    <!-- USER theme -->
    <div class="kng-boxed theme" hidden>
      <div class="label"> Changer de couleur</div>
      <div class="content">
        <div class="color">&nbsp;</div>
        <div class="color">&nbsp;</div>
        <div class="color">&nbsp;</div>
        <div class="color">&nbsp;</div>
      </div>
    </div>

    </div>
 
    <kng-mail-confirmation [user]="user" *ngIf="user.isAuthenticated()&&!user.isReady()"></kng-mail-confirmation>

    <!-- FEEDBACK -->
    <kng-feedback [config]="config" [user]="user" [hidden]="!user.isAuthenticated()">
    </kng-feedback>


    <!-- SUBSCRITION OPTIONS -->
    <kng-subsciption-control [config]="config"></kng-subsciption-control>




    <!-- MARKETPLACE -->
    <kng-nav-marketplace (updated)="doOpenMarket($event)" [hidden]="lockedHUB" [config]="config"
      [orders]="orders"></kng-nav-marketplace>

    <!-- DATE -->
    <!-- <kng-calendar [config]="config"  (updated)="currentShippingDay=$event"></kng-calendar> -->


    <!-- <h2 class="">Les coups coeur du marché</h2>  
    <section class="products ">
        <div class="product" *ngFor="let product of selected;let j=index">
          <div  class="media ">
            <img [src]="getImage(product)" />
            <div class="title">{{product.title}}</div>
          </div>          

        </div>
    </section> -->

  </div>

</kng-navbar>